import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import Button from '../../components/Button/Button';
import { loginAction } from '../../actions/loginActions';
import ExternalWrapper from '../../components/ExternalWrapper/ExternalWrapper';
import Link from '../../components/Link/Link';
import { push } from 'connected-react-router';
import icon from '../../assets/logo-icon.png';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import useMsal from '../../hooks/useMsal';
import {
  loginRequest,
  appTokenRequest,
  msalConfig,
} from '../../config/authConfig';

import './Login.scss';

// login route /login
const Login = ({ postLogin, login, redirect, translations }) => {
  const {
    landingPage__email_input,
    landingPage__forgotPassword_link,
    landingPage__password_input,
    landingPage__stayLoggedIn_checkbox,
  } = translations.byKeyTranslations;

  const [loading, setLoading] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const { msalLogin, getToken, isAuthenticated, idToken, token } =
    useMsal(msalConfig);

  // const stopSubmittingForm = () =>
  //   setTimeout(() => setSubmittingForm(false), 3000);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length === 0) {
        setSubmittingForm(true);
        // stopSubmittingForm();
        // clearTimeout(stopSubmittingForm);
      }
    }
  }, [isSubmitting, errors]);

  useEffect(() => {
    if (window.location.hash.substr(1)) {
      console.log('if (window.location.hash.substr(1)) CALLED');
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && !login.loggedIn) {
      console.log('if (isAuthenticated && !login.loggedIn) CALLED');
      setLoading(true);
    }
  }, [isAuthenticated, login]); // eslint-disable-line

  useEffect(() => {
    if (login.error) {
      console.log('if (login.error) CALLED');
      setLoading(false);
    }
  }, [login.error]); // eslint-disable-line

  useEffect(() => {
    if (login.loggedIn) {
      console.log('if (login.user && login.loggedIn) CALLED');

      redirect();
    }
  }, [login.loggedIn]); // eslint-disable-line

  useEffect(() => {
    if (isAuthenticated === true) {
      console.log('if (isAuthenticated === true) CALLED');
      getToken(appTokenRequest);
    }
  }, [isAuthenticated]); // eslint-disable-line

  useEffect(() => {
    if (token) {
      console.log('if (token) CALLED');
      postLogin({ strategy: 'architectus', idToken, token });
    }
  }, [token]); // eslint-disable-line

  return (
    <ExternalWrapper>
      <form onSubmit={handleSubmit(postLogin)}>
        <label>
          {landingPage__email_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </label>
        <input
          className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
          {...register('email', {
            required: ' Email is required',
          })}
          defaultValue=""
          type="email"
        />

        <label>
          {landingPage__password_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </label>
        <input
          className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
          {...register('password', {
            required: ' Password is required',
          })}
          defaultValue=""
          type="password"
        />

        <div className="login__buttons">
          <div>
            <input
              className="py-[7px] px-[5px] my-[5px] border border-[#DFE1E6] rounded text-bcapp-blue focus:ring-transparent focus:ring-offset-0 cursor-pointer"
              {...register('remember')}
              type="checkbox"
              defaultChecked={true}
            />

            <label className="pl-2">{landingPage__stayLoggedIn_checkbox}</label>
          </div>
          <Button loading={submittingForm}>Log in</Button>
        </div>
        <div className="login__out-buttons">
          <Link to="/forgot-password" inline dark>
            {landingPage__forgotPassword_link}
          </Link>
        </div>
        <div className="login__sso-buttons">
          You can also{' '}
          <span
            className="login__sso-link"
            onClick={() => msalLogin(loginRequest)}
          >
            continue with Architectus SSO
          </span>
        </div>
      </form>

      <div
        className={
          loading
            ? 'loading__container'
            : 'loading__container loading__container--animation'
        }
      >
        <div className="loading__icon">
          <img src={icon} alt="logo icon" />
        </div>
      </div>
    </ExternalWrapper>
  );
};

Login.propTypes = {
  postLogin: func,
  login: object,
  redirect: func,
  translations: object,
};

const mapstateToProps = ({ login, translations }) => ({
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  postLogin: (data) => {
    dispatch(loginAction(data));
  },
  redirect: () => dispatch(push('/')),
});

export default connect(mapstateToProps, mapDispatchToProps)(Login);
